<template>
  <v-date-picker v-model="initialLocal" >
    <template v-slot="{ inputValue, inputEvents, togglePopover }" >
      <CInput horizontal :label="label" :value="inputValue" @click.stop>
        <template #prepend>
          <CButton color="primary"
                   @click.stop="togglePopover()">
            <CIcon name="cil-calendar"/>
          </CButton>
        </template>
      </CInput>
    </template>
  </v-date-picker>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: "ButtonDateInput",
  components: {
    'v-date-picker': DatePicker
  },
  props: {
    label: {
      type: String,
      required: false
    },
    initial: {
      type: Date,
      required: false,
      default: function() {
        return new Date();
      }
    }
  },
  model: {
    prop: 'initial',
    event: 'datechanged'
  },
  computed: {
    initialLocal: {
      get: function() {
        return this.initial
      },
      set: function(value) {
        this.$emit('datechanged', value)
      }
    }
  }
};
</script>
