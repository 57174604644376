

















































































































import {vxm} from "@/store";
import {Component, Vue, Watch} from 'vue-property-decorator';
import {CaseApiFactory, CaseDTO, CaseFilterDTO, CaseStatus, Configuration} from "@shared_vue/openapi/caseapi";
import 'firebase/auth';

import {ServiceBusClient, ServiceBusReceiver} from "@azure/service-bus";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import ButtonDateInput from "@/views/viewcomponents/ButtonDateInput.vue"
import {CaseStore} from "@/store/case";


@Component({components: { WaitModal, ButtonDateInput } })
    export default class CaseStack extends Vue {
    private showToast: boolean = true; 
    private ui = vxm.ui;
    private caseStore = vxm.case;
    private showWait: boolean = false;
    private auth = vxm.auth;
    private imagePath = process.env.VUE_APP_STATIC_DIR + 'images/';
    private caseApi = CaseApiFactory(<Configuration>{basePath:process.env.VUE_APP_CASE_API_URL},process.env.VUE_APP_CASE_API_URL)
    private caseList: CaseDTO[] = []
    private waitABit = false;
    private serviceBusClient: ServiceBusClient | undefined;
    private receiver: ServiceBusReceiver|undefined;
    private checkedFilterStates: Map<string,boolean> = new Map<string, boolean>();
    private startDate: Date = this.defaultStartDate;
    private endDate: Date = this.defaultEndDate;
    private audio = new Audio(require('@/assets/sounds/case-alert.mp3')); // path to file
    private toasts: number = 0;
      

    @Watch('auth.idToken')
    onIdToken(val: boolean, oldval: boolean) {
      console.log('onidtoken')
      if (this.waitABit){
        this.fetchCases()
      }
    }

    private processToast(){
      console.log('process toast')
      //should we reset filter or what?
      this.clearFilter();
    }
    
  @Watch('startDate')
  onStartDateChange(val: Date, oldval: Date) {
    console.log('onstartdatechange')
    let currentFilter = this.caseStore.currentFiler;
    currentFilter.startDate = val.toJSON();
    this.caseStore.updateFilter(currentFilter); 
  }

  @Watch('endDate')
  onEndDateChange(val: Date, oldval: Date) {
    console.log('onsenddatechange')
    let currentFilter = this.caseStore.currentFiler;
    currentFilter.endDate = val.toJSON();
    this.caseStore.updateFilter(currentFilter);
  }
    
    get defaultEndDate(){
      if (this.caseStore.caseFilter) {
        if (this.caseStore.caseFilter.endDate){
          return new Date(this.caseStore.caseFilter.endDate);
        }
      }
      console.log('using default end date')
      return new Date(); //default is today (inclusive)
    }
    
    get defaultStartDate(){
      if (this.caseStore.caseFilter) {
        if (this.caseStore.caseFilter.startDate){
          return new Date(this.caseStore.caseFilter.startDate);
        }
      }
      //default
      console.log('using default start date')
      let monthAgo = new Date();
      monthAgo.setMonth(monthAgo.getMonth() -1)
      return monthAgo;
      
    }
    
    private visibility(which: string) : boolean{
      return this.caseStore.caseFilter.caseStates?.includes(which)??false;
    }
    
    get visibility_NEW(): boolean {
      return this.caseStore.caseFilter.caseStates?.includes('NEW')??false;
    }
    private filterCases(){
      
    }

      private async fetchCase(id: number){
      console.log('fetching case for id: ' + id);
        let response = await this.caseApi.caseGetCaseById(id)
        if (response.status == 200) {
          console.log(response.data)
          // need to deduplicate. If this id already exists just replace it
          let foundItem = this.caseList.find(a=>a.id==response.data.id);
          if (foundItem){
            let index = this.caseList.indexOf(foundItem);
            this.caseList[index]=response.data;
          } else {
            this.caseList.push(response.data);
          }
        }
      }
      
      private clearFilter(){
        this.caseStore.resetFilter();
        this.onEndDateChange(this.defaultEndDate, new Date());
        this.onStartDateChange(this.defaultStartDate, new Date());
        this.fetchCases();
        
      }

      private async fetchCases(){
        if (!this.auth.idToken) {
          this.waitABit = true;
          return;
        }

        try {
          this.showWait = true;
          // let freshToken = firebase.auth().currentUser?.getIdToken(true)
          //check for filter
          if (!this.caseStore.caseFilter||!this.caseStore.caseFilter.startDate){
            this.caseStore.updateFilter(CaseStore.getDefaultCaseFilter());
          }
          let response = await this.caseApi.caseGetFilteredCases(this.caseStore.caseFilter);
          //let response = await this.caseApi.caseGetCases();
          if (response.status == 200) {
            console.log(response.data)
            this.caseList = response.data;
          }
        } catch (e) {
          console.log(e)
        } finally {
          this.showWait = false;
        }
      }

      private onClickCase(item:any, index:any, column:any, event:any){
        console.log("onclickcase")
        console.log('column: ' + column)
        if (column==='select') { //don't navigate
          return;
        }
        let id = item.id;
        //note that the flow for "NEW" is different to the others
        let actualCase = this.caseList.find(c=>c.id==id);
        if (actualCase) {
          if (actualCase.status == CaseStatus.New) {
            //navigate to new flow
            this.$router.push(`/dashboard/casemanagement/${id}`)
          } else {
            //open case
            this.$router.push(`/dashboard/casecapture/${id}`)
          }
        }
      }

    private check (item:any) { //the whole data row is passed in
      console.log(item)
      // const val = Boolean(this.usersData[item.id]._selected)
      // this.$set(this.usersData[item.id], '_selected', !val)
    }
      mounted(){

        // I am not going to put the case stack in the store
        // I think it's just toooooo big
        // this.connection = new signalR.HubConnectionBuilder()
        //     .withUrl("http://localhost:5004/casehub")
        //     .withAutomaticReconnect()
        //     .build();
        //
        // this.connection.on("NotifyNewCase", data => {
        //   console.log('new case alert!')
        //   console.log(data); //data is the case id. Fetch just that case and add it to the list :-O :-O :-O
        //   this.fetchCase(data);
        //   //we do NOT want to fetch all. Fetch by ID
        // });
        // this.connection.start();
        
        // this.showToast = false;

        this.configureSB();
        
        
        this.fetchCases();
      }
      
      private checkNewCaseVisibility(event: any) : boolean {
        
        let passStates: boolean = true;
        let passTypes: boolean = true;
        let passStart: boolean = true;
        let passEnd: boolean = true;

        let actualReportDate = Date.parse(event.ReportedTime);
        //message.body: {"CaseId":"8","Event":5,"Source":"CaseController","Extra":"{}","Meta":null,"OwnerGuid":"OkVm0hNyuvMTMnyyc7l5wT5XjTR2","OpGuid":"","OriginalId":"","CaseType":"Missing Child","CaseState":"NEW","ReportedTime":"2022-01-26T07:16:47.3967545+00:00","EventName":"New Case","EventId":"fac1bdf4-0fe8-4309-9d6c-3cf1b92aa75b","EventTime":"2022-01-26T07:16:47.8162363+00:00"}
        console.log('checking case visibility')
        if (this.caseStore.caseFilter) {
          if (this.caseStore.caseFilter.caseStates) { //else true
            if (this.caseStore.caseFilter.caseStates.length > 0) { //else true
              passStates = !!this.caseStore.caseFilter.caseStates.includes(event.CaseState);
            }
            if (this.caseStore.caseFilter.caseTypes) {
              if (this.caseStore.caseFilter.caseTypes.length > 0) { //else true
                passTypes = !!this.caseStore.caseFilter.caseTypes.includes(event.CaseType);
              }
            }
            if (this.caseStore.caseFilter.startDate) {
              if (actualReportDate < Date.parse(this.caseStore.caseFilter.startDate)){
                passStart = false;
              }
            }
            if (this.caseStore.caseFilter.endDate) {
              if (actualReportDate > Date.parse(this.caseStore.caseFilter.endDate)){
                passEnd = false;
              }
            }
          } else {
            return true; //everything visible with no filter
          }
          
        }
        return passStates && passTypes && passStart && passEnd;
    }
    
    private toasteMe(){
      this.toasts++;
    }

    private myMessageHandler = async (message: any) => { //type this?
      // your code here
      console.log('message.body: ' +JSON.stringify(message.body));
      // fetch just this case so we don't overwhelm everything all the time
      //if a filter is applied, we must not necessarily just fetch it. 
      //message.body: {"CaseId":"8","Event":5,"Source":"CaseController","Extra":"{}","Meta":null,"OwnerGuid":"OkVm0hNyuvMTMnyyc7l5wT5XjTR2","OpGuid":"","OriginalId":"","CaseType":"Missing Child","CaseState":"NEW","ReportedTime":"2022-01-26T07:16:47.3967545+00:00","EventName":"New Case","EventId":"fac1bdf4-0fe8-4309-9d6c-3cf1b92aa75b","EventTime":"2022-01-26T07:16:47.8162363+00:00"}
      if (message.body.EventName=="New Case"){ //should we play a sound?
        this.audio.play();
        if (!this.checkNewCaseVisibility(message.body)){ //rather determine if this should be visible based on a variety of criteria. Otherwise show like a dot or something with a count
          //just alert
          console.log('just toasting');
          this.toasteMe();
        } else {
          this.fetchCase(message.body.CaseId);
        }
      }
    };
    private myErrorHandler = async (args: any) => {
      console.log(
          `Error occurred with ${args.entityPath} within ${args.fullyQualifiedNamespace}: `,
          args.error
      );
    };
    
    private clickFilter(which: string){
      console.log(which);
      this.caseStore.toggleVisible(which);
      //refresh the view
      // this.fetchCases();
    }
  
      private async configureSB(){
        let connectionString = await this.caseApi.caseGetConnectionString();
        console.log('sb connectionstring: ' + connectionString.data);
        this.serviceBusClient = new ServiceBusClient(connectionString.data);
        this.receiver = this.serviceBusClient.createReceiver("cases", <string>this.auth.UserId);
    
        this.receiver.subscribe({
          processMessage: this.myMessageHandler,
          processError: this.myErrorHandler
        });
      }

      private fields = [
        {key: 'id'},
        {key: 'caseType', label: 'Case Type', _style: 'width:40px'},
        {key: 'status', _style: 'width:60px;'},
        {key: 'userName', label: 'Username and Surname', _style: 'min-width:200px;'},
        {key: 'contactNumber', label: 'Contact Number', _style: 'width:100px;'},
        {key: 'caseNumber', label: 'IMIC Case #'},
        {key: 'complaint', _style: 'min-width:200px;'},
        {key: 'caseOfficer', label: 'Case Officer',_style: 'min-width:200px;'},
        {key: 'reported', _style: 'width:80px;'},
        {key: 'currentBranch', label: 'ERPC', _style: 'min-width:100px;', sorter: false,},
        {
          key: 'select',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ];

      private getColor(status: any) {
        switch (status) {
          case 'new':
            return '#FDA6A6';
          case 'active':
            return '#A6FDAC';
          case 'pending':
            return '#FDF0A6';
          case 'closed':
            return '#A6BAFD';
          default:
            '#FDA6A6'
        }
      };

      private getImage(caseType: any) {
        if (caseType) {
          return this.imagePath + caseType
        }
      };

      // get items2() {
      //   return this.items.map((item, id) => {
      //     return {...item, id}
      //   });
      // }

      private addRow(){


      }

    }


